import { keyframes } from '@emotion/react'

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50%  {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`

export default {
  // ? =================================
  // ? =====  Multilocation Page  ======
  // ? =================================

  multiLocationPageAbout: {
    minHeight: ['', '', '100vh'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  multiLocationPageAbout2: {
    padding: '6rem 1rem 6rem',
    backgroundColor: '#efefef',
    '.title': {
      marginBottom: '2.5rem'
    },
    '.text': {
      color: 'black'
    },
    display: 'flex',
    alignItems: 'center',
    '.section': {
      height: 'fit-content',
      // borderBottom: 'double 9px black',
      // borderTop: 'double 9px black',
      padding: '2rem'
    }
  },

  multiLocationPageAbout3: {
    // height: '70vh',
    color: 'white',
    padding: ['5rem 1rem'],
    '.title': {
      color: 'primary',
      fontWeight: '300',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.text': {
      color: 'white',
      fontSize: ['0.9rem', '1.1rem']
    },
    display: 'flex',
    alignItems: 'center',
    '.section': {
      backgroundColor: '#000000cc',
      border: 'solid 2px',
      borderColor: 'primary',
      height: 'fit-content',
      padding: '1.5rem'
    }
  },

  multiLocationPageEvents: {
    padding: ['0rem', '', '4rem 2rem', '5rem 4rem 10rem'],
    '.contentBoxes': {
      padding: '0rem',
      'a:nth-of-type(even)': {
        position: ['', '', 'relative'],
        top: ['', '', '9rem']
      }
    },
    '.box': {
      padding: '1rem',
      width: ['100%', '', '50%'],
      minHeight: '50vh'
    },
    '.image': {
      // order: '4',
      objectFit: 'cover',
      minHeight: '50vh'
    },
    '.title': {
      variant: 'customVariants.title',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    },
    '.text': {
      variant: 'customVariants.text',
      padding: ['1rem', '', '1rem', '2rem 3rem 1rem']
    }
  },

  pageheroFade: {
    position: 'relative',
    borderBottom: '0rem'
    // '::after': {
    //   background:
    //     'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #e1e1e1 100%)',
    //   bottom: '0rem',
    //   content: '""',
    //   height: '100px',
    //   left: '0rem',
    //   position: 'absolute',
    //   width: '100%',
    //   zIndex: '1',
    // },
  },

  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '2',
    textTransform: 'uppercase',
    marginBottom: '2.25rem',
    letterSpacing: '-2px',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    padding: '1rem 0rem',
    width: 'fit-content'
  },
  subtitle: {
    fontWeight: '300',
    opacity: '0.8',
    margin: '0rem 0rem 1.5rem',
    order: '1'
  },

  text: {
    order: '3',
    fontSize: '0.9rem',
    lineHeight: '2'
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '> .container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem']
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', 'auto auto auto 1rem'],
        position: ['', '', '', 'static'],
        padding: '0rem',
        '.container': {
          // padding: ['0.5rem'],
        },
        '.logoScrolled ': {
          maxWidth: '150px',
          marginBottom: '0rem',
          maxHeight: 'unset',

          display: 'inline',
          padding: '0rem',
          a: {
            display: 'flex'
          },
          img: {
            filter: 'brightness(0) invert(1)',
            maxHeight: 'unset',
            maxWidth: '150px',
            height: 'unset',
            padding: '0rem'
          }
        },
        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      '.container': {
        // padding: ['0.5rem'],
      },
      '.logo': {
        maxWidth: '150px',
        marginBottom: '0.5rem'
      },
      '.locationSwitcher': {
        mt: '0.5rem',
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        },
        '.logo': {
          mb: '2rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      div: {
        backgroundColor: 'white'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      '.navItem': {
        textAlign: 'right',
        a: {
          fontSize: '1.2rem'
        }
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '300px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundImage: "url('https://res.cloudinary.com/gonation/w_1500/q_auto/f_auto/sites/limanigrille/navmenu.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: '#9d8076',
    borderTop: '#fafafa 2px solid',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    '::after': {
      content: `"© ${new Date().getFullYear()} Limani"`,
      width: '100%',
      backgroundColor: '#202020',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px',
      filter: 'brightness(0) invert(1)'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      color: 'white',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'black',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '4rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'white',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'primary',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.gonationLogo': {
      filter: 'brightness(0) invert(1)'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'white'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'white'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    },
    '.locationSwitcherV1': {
      '> .button': {
        border: 'solid 1px white',
        ':hover': {
          backgroundColor: 'dark',
          color: 'white'
        }
      },
      '.logo': {
        filter: 'brightness(0)'
      }
    }
  },

  sideBySide1: {
    minHeight: ['', '', 'calc(100vh - 77px)'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    '.section': {
      'div:nth-of-type(1)': {
        order: '2'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '4rem', '6rem', '7rem', '8rem'],
      letterSpacing: ['1px', '', '7px', '10px'],
      fontWeight: '400',
      textTransform: 'uppercase',
      margin: '0rem',
      padding: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.25rem', '1.5rem', '2rem', '2.5rem', '3rem'],
      letterSpacing: ['1px', '', '7px', '10px'],
      fontWeight: '400',
      margin: '0rem',
      textTransform: 'uppercase',
      order: '3',
      padding: '0rem'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },
  homepageShout: {
    // maxHeight: ['', '', '25vh'],
    backgroundColor: 'background',
    color: 'white',
    margin: ['auto', '', '2rem auto'],
    width: ['', '', 'calc(100% - 6rem)'],
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.imageContainer': {
      order: ['3', '', 'unset']
    },
    '.content': {
      padding: ['1rem', '', '', '2rem', '2rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      paddingLeft: '8px'
      //   display: 'none',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.date': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'primary'
    },
    '.text': {
      variant: 'customVariants.text',
      fontFamily: 'heading',
      textAlign: 'left',
      margin: '1rem 0rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: '2px',
      color: 'white',
      backgroundColor: 'dark'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGiftCards: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: '#c5c1af'
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      margin: '0rem 2rem 3rem',
      order: '4',
      border: 'solid 2px white',
      backgroundColor: 'transparent',
      width: 'fit-content',
      borderRadius: '0px',
      textTransform: 'uppercase',
      padding: '0.5rem 2rem',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary'
      }
    }
  },

  homepageReservation: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['6rem 1rem', '', '10rem 1rem'],
    flexDirection: 'column',
    backgroundColor: '#f3f3f3',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  homepageLocationBoxes: {
    padding: '0rem',
    '.box': {
      flexGrow: '1',
      backgroundColor: 'black',
      width: ['50%', '', '33.3%', '20%'],
      ':hover img': {
        opacity: '0.5'
      }
    },
    '.image': {
      height: '100px',
      objectFit: 'contain',
      transition: 'all ease-in-out 0.3s',
      padding: '0.5rem 2rem',
      filter: 'brightness(0) invert(1)'
    },
    '.title': {
      display: 'none'
    },

    '.subtitle': {
      display: 'none'
    },
    '.text': {
      display: 'none'
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutPageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  menuPDFMenu: {
    paddingBottom: '0rem',
    '.content': {
      display: 'none'
    }
  },

  menu: {
    border: 'solid 1px black',
    padding: '1rem',
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      margin: '0rem 0rem 2rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.menuSectionDescription': {
      margin: '0rem auto 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px'
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      display: 'none'
    },
    '.variantsContainer': {
      display: 'none'
    }
  },

  menuCTAS: {
    '.section': {
      maxWidth: 'unset'
    },
    '.linksContainer': {
      '.ctaButton, .secondaryCtaButton': {
        variant: 'buttons.primary',
        borderRadius: '0px'
      }
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    // '.albumsContainer': {
    //   display: 'none',
    // },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },
  //  ? =============================
  //  ? =====  Gift Cards Page  =====
  //  ? =============================

  giftcardSection: {
    background: 'none',
    zIndex: '10',
    margin: '0rem auto',
    paddingTop: '0rem',
    '.title': {
      position: 'relative',
      textTransform: 'uppercase',
      color: 'primary',
      top: '-5rem',
      fontSize: ['3rem', '4rem', '6rem', '7rem'],
      textShadow: '2px 2px 0px black'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  //  ? =============================
  //  ? ====  Private Room page  ====
  //  ? =============================

  privateroom: {
    width: '100%',
    maxWidth: 'unset',
    backgroundColor: 'transparent',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    borderRadius: '0px',
    marginBottom: '0rem',
    '.innerMenuContainer': {
      padding: '3rem 1rem'
    },
    '.allInContainerWrapper': {
      backgroundColor: 'transparent'
    },
    '.menuSectionTitle': {
      display: 'none'
    },

    '.menuSectionDescription': {
      maxWidth: '767px',
      margin: '0rem auto 2rem',
      lineHeight: '1.75',
      fontSize: '1.25rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      margin: ['', '', '1rem'],
      border: 'solid 1px black'
    },

    '.menuItemImageContainer': {
      height: '400px',
      width: '100%'
    },
    '.menuItemContentContainer ': {
      height: 'auto',
      padding: ['1rem', '2rem', '1rem', '2rem'],
      flexGrow: '1',
      alignItems: 'center'
      // justifyContent: 'center',
    },

    '.menuItemNamePriceContainer': {
      width: '100%'
    },

    '.menuItemName': {
      marginBottom: '2rem',
      width: '100%',
      fontSize: ['1.5rem', '', '2rem', '2.5rem'],
      borderBottom: '1px solid',
      paddingBottom: '0.5rem'
    },
    '.menuItemDescription': {
      width: '100%'
    },
    '.menuItemImgDefault, .menuItemImgDefaultContainer': {
      display: 'none'
    }
  }
}
